<template>
  <div class="banner">
    <!-- 面包屑 -->
    <el-breadcrumb separator=">">
      <el-breadcrumb-item v-for="(item,i) in breadcrumb" :key="i" :to="item">{{item.title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 个人信息 -->
    <el-dropdown @command="handleCommand">
      <span class="personal-information">
        <img :src="user.image" class="photo">
        <span>{{user.name}}</span>
        <i class="el-icon-arrow-down"></i>
      </span>
      <template #dropdown>
        <el-dropdown-menu v-if="isManagement === '0'">
          <el-dropdown-item command="changeInstitution">切换企业</el-dropdown-item>
        </el-dropdown-menu>
        <el-dropdown-menu>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>
<script>
import { computed, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from '@/common/utils/axios'
import { ElBreadcrumb, ElBreadcrumbItem, ElDropdown, ElDropdownItem, ElDropdownMenu } from 'element-plus'
export default {
  components: { ElBreadcrumb, ElBreadcrumbItem, ElDropdown, ElDropdownItem, ElDropdownMenu },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    /**
     * 指令列表
     */
    const commandList = {
      changeInstitution: () => { // 切换企业
        router.push({ path: '/institution/choose-enterprise' })
      },
      logout: () => { // 退出登录
        axios('/admin/user/logout', {})
          .then(res => {
            router.push({ path: '/login' })
          }).catch(_ => { })
      }
    }
    const handleCommand = (command) => {
      commandList[command]()
    }

    return {
      isManagement: inject('isManagement'),
      breadcrumb: computed(() => store.state.breadcrumb),
      handleCommand
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - $aside-width);
  margin-left: $aside-width;
  height: $banner-height;
  box-sizing: border-box;
  padding: 0 20px;
  .el-breadcrumb :deep {
     .el-breadcrumb__inner {
      color: $font-black;
      font-weight: bold;
    }
  }
  .personal-information {
    cursor: pointer;
    display: flex;
    align-items: center;
    * {
      margin-left: 10px;
    }
    .photo {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
