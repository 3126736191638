<template>
  <div class="main">
    <router-view class="router-view"/>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.main {
  position: absolute;
  width: calc(100% - $aside-width);
  height: calc(100% - $banner-height);
  margin-left: $aside-width;
  background-color: $bg-gray;
  .router-view {
    margin: 15px;
    background-color: #fff;
    border-radius: 3px;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
  }
}
</style>
