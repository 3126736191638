<template>
  <div class="home">
    <v-aside :menu="menu"></v-aside>
    <v-banner :user="user.data" v-if="initFlag"></v-banner>
    <v-main v-if="initFlag"></v-main>
  </div>
</template>
<script>
import VAside from './components/aside.vue'
import VBanner from './components/banner.vue'
import VMain from './components/main.vue'
import { useRoute } from 'vue-router'
import { onMounted, reactive, ref, provide } from 'vue'
import axios from '@/common/utils/axios'

export default {
  components: { VAside, VBanner, VMain },

  setup () {
    const route = useRoute()

    const initFlag = ref(false)

    const isManagement = route.query.isManagement || '0'
    provide('isManagement', isManagement)
    const cid = isManagement === '1' ? null : route.query.cid
    provide('cid', cid)
    const isManager = route.query.isManager
    provide('isManager', isManager)

    // 菜单
    let menu = []
    if (isManagement === '1') {
      menu = [
        { title: '企业管理', type: '1', icon: 'school', path: '/manage-institution', defaultActive: true },
        { title: '提现管理', type: '1', icon: 'money', path: '/manage-platform-withdraw-record-list', defaultActive: true },
        { title: '小萌私域首页', type: '1', icon: 'postcard', path: '/manage-platform-index', defaultActive: false },
        { title: '套餐订单', type: '1', icon: 'coin', path: '/manage-platform-setmeal-order', defaultActive: false },
        { title: '小程序模板', type: '1', icon: 'office-building', path: '/manage-mini-template', defaultActive: false },
        { title: '商品模板', type: '1', icon: 'goods', path: '/manage-goods-mini-template', defaultActive: false },
        { title: '图文模板', type: '1', icon: 'document', path: '/manage-desc-template', defaultActive: false },
        { title: '图文组合模板', type: '1', icon: 'folder', path: '/manage-desc-group-template', defaultActive: false },
        { title: '宣传栏组合模板', type: '1', icon: 'postcard', path: '/manage-banner-group-template', defaultActive: false },
        { title: '商品海报模板', type: '1', icon: 'goods', path: '/manage-goods-share-poster-template', defaultActive: false },
        { title: '文章海报模板', type: '1', icon: 'document-copy', path: '/manage-article-share-poster-template', defaultActive: false },
        { title: '营销消息模板', type: '1', icon: 'goods', path: '/manage-marketing-msg-poster-template', defaultActive: false },
        { title: '助力消息模板', type: '1', icon: 'goods', path: '/manage-assist-msg-poster-template', defaultActive: false },
        { title: '小程序版本', type: '1', icon: 'magic-stick', path: '/manage-thirdparty-template', defaultActive: false }
      ]
    } else if (isManager === '1') {
      menu = [
        { title: '商品', type: '1', icon: 'goods', path: '/goods/list', defaultActive: true },
        { title: '订单', type: '1', icon: 'box', path: '/enroll/list', defaultActive: false },
        { title: '设置', type: '1', icon: 'setting', path: '/settings', defaultActive: false }
      ]
    } else {
      menu = [
        { title: '商品', type: '1', icon: 'goods', path: '/goods/list', defaultActive: true }
      ]
    }

    /**
     * 获取用户信息
     */
    const user = reactive({ data: {} })
    const getUserInfo = (url, params) => {
      return new Promise((resolve) => {
        axios(url, params)
          .then(res => {
            resolve(res)
          }).catch(_ => { })
      })
    }

    onMounted(async () => {
      if (cid) { // 管理后台
        const res = await getUserInfo('/admin/index', { cid: cid })
        user.data = res.member
      }
      if (isManagement === '1') { // 运营后台
        const res = await getUserInfo('/manager/index', {})
        user.data = res.manager
      }
      // 调用完用户信息（后台存入信息至cookie），才加载内容
      initFlag.value = true
    })
    return {
      initFlag,
      menu,
      user
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  position: absolute;
  height: 100%;
  width: 100%;
  min-width: 1200px;
}
</style>
