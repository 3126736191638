<template>
  <aside>
    <img :src="require('@/assets/image/logo_white.png')" class="logo"  @click="toWebsite">
    <el-menu
      active-text-color="#606266"
      background-color="#303133"
      text-color="#fff"
      :default-active="defaultPath"
      mode="vertical"
      router>
        <div v-for="(item,index) in menu" :key="index">
          <el-menu-item v-if="item.type== '1'" :index="item.path" @click="clickMenu">
            <i :class="`el-icon-${item.icon}`"></i>
                {{item.title}}
          </el-menu-item>
        </div>
    </el-menu>
  </aside>
</template>

<script>
import { ElMenu, ElMenuItem } from 'element-plus'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { inject } from 'vue'
export default {
  components: {
    ElMenu, ElMenuItem
  },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()

    // 默认菜单
    const defaultMenu = props.menu.find(item => item.defaultActive)
    const defaultPath = defaultMenu.path
    router.replace({
      path: defaultPath,
      query: {
        isManagement: inject('isManagement'),
        cid: inject('cid')
      }
    })
    /**
     * 点击菜单 -- 清空breadCrumb
     */
    const clickMenu = () => {
      store.commit('spliceBreadcrumb', 0)
    }

    /**
     * 回到官网页
     */
    const toWebsite = () => {
      router.push('/')
    }
    return {
      defaultPath,
      clickMenu,
      toWebsite
    }
  }
}
</script>
<style lang="scss" scoped>
aside {
  position: absolute;
  width: $aside-width;
  height: 100%;
  background-color: $bg-black;
  display: flex;
  flex-direction: column;
  align-items: center;
  // overflow: hidden;
  .logo {
    margin: 20px 0;
    width: 70%;
    cursor: pointer;
  }
  .el-menu {
    flex: 1;
    width: $aside-width;
    border-right: 0;
    .el-menu-item{
      &.is-active{
        background: $bg-gray
      }
    }
  }
}
</style>
